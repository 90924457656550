<template>
  <div>
    <div style="margin: 20px 0 0 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="map-info">
      <div class="layout-content" >
        <div v-if="!loading">
          <Row>
            <i-col span="2">
              <!--            <img v-if="siteDetail.logo" :src="siteDetail.logo">-->
              <div class="head-img">
                <p>{{mapDeatil.name && mapDeatil.name[0].toUpperCase()}}</p>
                <!--            <img v-if="taskDeatil.project.category" :src="constant.STATIC_URL + taskDeatil.project.category.thumbnail" style="width: 70%;"/>-->
              </div>
            </i-col>
            <i-col span="22">
              <Row>
                <i-col span="24" class="project-detail-info">
                  <div class="info-item" >
                    <strong style="font-size:16px; max-width: 600px; display: inline-block;padding-left: 10px;">{{ mapDeatil.name }}</strong>
                  </div>
                </i-col>
              </Row>
              <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
                <i-col span="6">ID :   {{mapDeatil.id}}</i-col>
                <i-col span="6">{{ $t('dataset_map_detail_create_date') }} :  {{mapDeatil.created_at}}</i-col>
                <i-col span="6">{{ $t('dataset_map_detail_update_date') }} :   {{mapDeatil.updated_at}}</i-col>
                <i-col span="6">{{ $t('dataset_map_detail_remark') }} :   </i-col>
              </Row>
              <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
                <i-col span="6">关联分类 :   {{mapDeatil.category ? mapDeatil.category.name + '(id:'+mapDeatil.category.id+')': ''}}</i-col>
              </Row>
            </i-col>
          </Row>
        </div>
        <Spin fix v-else></Spin>
      </div>
      <div class="cont">
        <div class="cont-top">
          <div class="tab">
            <div :class="curTab.id == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</div>
          </div>
        </div>
        <div class="cont-item">
          <component :is="curTab.componentName" :tree="mapDeatil.table_tree"></component>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import util from '@/utils/tools.js';
import Chart from './components/map/chart'
import NodeList from './components/map/nodeList'
import SiteList from './components/map/siteList'

export default {
  name: "map-info",
  data(){
    return{
      mapDeatil:{},
      mapId:'',
      loading:false,
      typesObj:{},
      tabList:[
        {
          name:this.$t('dataset_map_detail_chart'),
          id:1,
          componentName:'Chart',
          tabName:'chart'
        },
        {
          name:this.$t('dataset_map_detail_node'),
          id:2,
          componentName:'NodeList',
          tabName:'nodeList'
        },
        // {
        //   name:'组织绑定',
        //   id:3,
        //   componentName:'SiteList',
        //   tabName:'siteList'
        // }
      ],
      curTab:{
        name:this.$t('dataset_map_detail_chart'),
        id:1,
        componentName:'Chart',
        tabName:'chart'
      },
    }
  },
  components:{
    Chart,
    NodeList,
    SiteList
  },
  created(){
    this.curTab = this.tabList.filter((item)=>{
      return item.tabName == this.$route.params.tab;
    })[0];
    this.mapId = this.$route.params.id;
    this.getDetail()
  },
  methods:{
    getDetail(){
      let params = {
        id:this.mapId,
        site_id:-1,
      };
      this.loading = true;
      this.api.dataset.mapDetail(params).then((res)=>{
        this.loading = false;
        this.mapDeatil = res.info;
        this.typesObj = res.types;

        // this.examDeatil.batch.name = util.decrypt(this.examDeatil.batch.name);

        this.mapDeatil.created_at = util.timeFormatter(
            new Date(+res.info.created_at * 1000),
            'yyyy-MM-dd'
        );
        this.mapDeatil.updated_at = util.timeFormatter(
            new Date(+res.info.updated_at * 1000),
            'yyyy-MM-dd'
        );
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeTab(data){
      this.curTab = data;
      this.$router.push({
        name: 'map-detail',
        params: {
          id: this.$route.params.id,
          tab: data.tabName,
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.map-info{
  .layout-content{
    margin: 20px;
    padding: 20px;
    background: #ffffff;
    min-height: 100px;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    position: relative;

    .project-detail-info {
      display: flex;
      justify-content: space-between;
    }
    .head-img{
      width: 80px;
      height: 80px;
      border-radius: 80px;
      line-height: 80px;
      background-color: #ccc;
      text-align: center;
      font-size: 55px;
      color:#FFFFFF;
      >img{
        width: 100%;
        height: 100%;
        border-radius: 80px;
      }
    }
    .project-detail-info .info-item button {
      margin-bottom: 5px;
    }
    .project-detail-info .info-item button + button {
      margin-left: 5px;
    }
    .info-item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tit-tag{
        padding: 5px 10px;
        text-align: center;
        color:#FFFFFF;
        border-radius: 4px;
        cursor: pointer;
        background-color:#2d8cf0 ;
      }
    }
    .progress{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        width: 60px;
      }
    }
  }
  .cont{
    margin: 0 20px 20px 20px;
    padding: 20px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    .cont-top{
      display: flex;

    }
    .tab{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border:1px solid #dcdee2;
      text-align: center;
      height: 46px;
      border-radius: 4px;
      overflow: hidden;
      font-size: 14px;
      cursor: default;
      line-height: 46px;

      >div{
        width: 100px;
        border-right:1px solid #dcdee2;
        cursor: pointer;
        height: 100%;
      }
      >div:last-child{
        border-right:none;
      }
      >div.active{
        background-color: #2d8cf0;
        color:#FFFFFF;
      }
    }
    .cont-item{
      margin-top: 20px;
    }
  }
}
</style>
