<template>
<div>
  <table class="table">
    <tr>
      <th :colspan="2+ maxLengh">考核范围</th>
      <th style="width: 120px">考核知识点/考点</th>
      <th style="width: 120px">考核知识点/关键词</th>
      <th style="width: 120px">考核知识点/试题</th>
    </tr>
    <tr>
      <th>项目</th>
      <th>职业技能</th>
      <th :colspan="maxLengh">内容</th>
      <th rowspan="2">关联考点</th>
      <th rowspan="2">关联关键词</th>
      <th rowspan="2">关联试题</th>
    </tr>
    <tr>
      <th>
        <div style="height: 40px">
          <p>名称</p>
          <p>权重</p>
        </div>
      </th>
      <th>
        <div style="height: 40px">
          <p>名称</p>
          <p>权重</p>
        </div>
      </th>
      <!--        <th>-->
      <!--          <div style="height: 40px">-->
      <!--            <p>一级知识点</p>-->
      <!--            <p>权重</p>-->
      <!--          </div>-->
      <!--        </th>-->
      <th v-for="(i,index) in maxLengh">
        <div style="height: 40px">
          <p>{{upNumber[index]}}级知识点</p>
          <p>权重</p>
        </div>
      </th>
    </tr>
    <tr v-for="(item,index) in treeData">
      <td v-for="(sItem,sIndex) in item" :rowspan="sItem.children_count == 0 ? Number(sItem.children_count) + 1 : sItem.children_count" v-if="sItem">
        <div v-if="sItem.id && sItem.id != -1" :style="{height:((!sItem.children_count ||sItem.children_count == 0) ? (Number(sItem.children_count) + 1) * 40 : Number(sItem.children_count) * 40) + (sItem.children_count == 0 ?  0 : Number(sItem.children_count)) + 'px'}">
          <p>{{ sItem.node.name }}({{sItem.data_count}})</p>
          <p>{{sItem.weight}}%</p>
        </div>
      </td>
      <td v-if="index<= treeData.length -1">
        <div class="table-label" style="cursor: pointer;align-items: center" >
          <Tooltip >
            <span style="display:block;width: 100px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;justify-content: flex-start;">{{item[item.length - 2].label_keypoints_count}}</span>
            <div slot="content" style="white-space: normal">{{item[item.length - 2].label_keypoints_count}}</div>
          </Tooltip>
          <!--          <span class="label-item" v-for="sItem in item[item.length - 2].labels">{{sItem.name}}<Icon type="md-close" style="margin-left: 5px" @click.stop="deleLabel(sItem,item)" /></span>-->
        </div>

      </td>
      <td v-if="index<= treeData.length -1">
        <div class="table-label" style="cursor: pointer;align-items: center" >
          <Tooltip >
            <span style="display:block;width: 100px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;justify-content: flex-start;">{{item[item.length - 2].label_keywords_count}}</span>
            <div slot="content" style="white-space: normal">{{item[item.length - 2].label_keywords_count}}</div>
          </Tooltip>
<!--          <span class="label-item" v-for="sItem in item[item.length - 2].labels">{{sItem.name}}<Icon type="md-close" style="margin-left: 5px" @click.stop="deleLabel(sItem,item)" /></span>-->
        </div>

      </td>
      <td v-if="index<= treeData.length -1">
        <div class="table-label" style="cursor: pointer;align-items: center" >
          <Tooltip >
            <span style="display:block;width: 100px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;justify-content: flex-start;">{{item[item.length - 2].datas_count || item[item.length - 3].datas_count}}</span>
            <div slot="content" style="white-space: normal">{{item[item.length - 2].datas_count || item[item.length - 3].datas_count}}</div>
          </Tooltip>
          <!--          <span class="label-item" v-for="sItem in item[item.length - 2].labels">{{sItem.name}}<Icon type="md-close" style="margin-left: 5px" @click.stop="deleLabel(sItem,item)" /></span>-->
        </div>

      </td>
    </tr>
    <tr>
      <td v-for="(item,index) in weightTotal" :key="index">
        <div style="height: 40px">
          <p>权重合计</p>
          <p>{{item}}%</p>
        </div>
      </td>
    </tr>
  </table>
</div>
</template>

<script>
export default {
  name: "chart",
  data(){
    return{
      treeData:[],
      cacheData:[],
      maxLengh:0,
      weightTotal:[],
      upNumber:['一','二','三','四','五','六'],
    }
  },
  props:{
    tree:{
      default:()=>{return []},
      type:Array
    }
  },
  watch:{
    tree:{
      handler(newV){
        if(newV.length){
          // console.log('1111')
          this.treeData = JSON.parse(JSON.stringify(newV));
          this.formate();
        }
      },
      immediate:true
    }
  },
  mounted(){

  },
  methods:{
    formate(){
      this.cacheData = JSON.parse(JSON.stringify(this.treeData));
      let length = 0;
      this.treeData.filter(function (item) {
        if (item.length > length) {

          return (length = item.length);
        }
      });

      this.maxLengh = length > 2 ? length - 1 : 1;


      let dataLen = this.cacheData.length;
      let addRow = 0;//一共添加了多少行


      this.treeData.forEach((item,index)=>{
        if(item.length < 3){
          let len = (length + 1 > 3 ? length + 1 : 3) - item.length;
          for(let i=0;i<len;i++){
            item.push({
              id:''
            })
          }
        }else if(item.length < length + 1){
          let len = length - item.length + 1;
          for(let i=0;i<len;i++){
            item.push({
              id:''
            })
          }
        }
      })

      this.weightTotal = [];
      this.treeData.forEach(item => {
        item.forEach((sItem, index) => {
          // console.log(sItem.weight,'item.weight')
          if(sItem.weight){
            if(this.weightTotal[index]){
              this.weightTotal[index] += Number(sItem.weight);
            }else{
              this.weightTotal[index] = Number(sItem.weight);
            }
          }
          if(sItem.labels && sItem.labels.length){
            let strLabels = sItem.labels.map((labelItem)=>{
              return labelItem.name;
            }).join(',')
            this.$set(sItem,'labelsStr',strLabels);
          }
          // if(sItem.datas && sItem.datas.length){
          //   let strDatas = sItem.datas.map((dataItem)=>{
          //     return dataItem && dataItem.name;
          //   }).join(',')
          //   this.$set(sItem,'datasStr',strDatas);
          // }
          // if(sItem.label_keypoints && sItem.label_keypoints.length){
          //   let strLabelKeypoints = sItem.label_keypoints.map((dataItem)=>{
          //     return dataItem && dataItem.name;
          //   }).join(',')
          //   // console.log(strLabelKeypoints,'strLabelKeypointsstrLabelKeypoints======')
          //   this.$set(sItem,'strLabelKeypoints',strLabelKeypoints);
          // }
          // if(index > 0 && !sItem.id && item[index - 1].strLabelKeypoints){
          //   this.$set(sItem,'strLabelKeypoints', item[index - 1].strLabelKeypoints);
          // }
        });

      });
      console.log(this.treeData,'this.treeData++++++')
    },
    setMergeCallNum(index,length){
      let len=0; //判断有没有找到数据  只有找到了才继续找上一位的
      for(let ind =index ;ind>=0;ind--){
        let status = false;
        if(this.treeData[ind][0]) {
          status = true;
        }
        for(let j=length;j>=0;j--){  //index以上的所有元素children_count + 1；
          if(this.treeData[ind][j] && this.treeData[ind][j].id != -1) {
            this.treeData[ind][j].children_count = Number(this.treeData[ind][j].children_count) + 1;
            len++;
          }else{
            if(j > 0) {
              status = true;
              // this.setMergeCallNum(ind -1,j-len);
              break;
            }
          }
          if(j ==0 && this.treeData[ind][0]){
            status = true;
            break
          }
        }
        if(status) break;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.table{
  width: 100%;
  border: 1px solid #dcdee2;
  tr{
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #dcdee2;
    overflow: hidden;

    td,th{
      text-align: center;
      border-right: 1px solid #dcdee2;
      position: relative;
      //display: flex;
      >div{
        display: flex;
        justify-content: center;
        div,p{
          display: flex;
          flex: 1;
          justify-content: center;
          border-right: 1px solid #dcdee2;
          align-items: center;
          line-height: 20px;

        }
        div:last-child,p:nth-child(2){
          border-right: none;
        }
      }

    }
    //.op-btn{
    //  position: absolute;
    //  bottom:0;
    //  left: 0;
    //  background-color:rgba(0,0,0,0.7) ;
    //  color:#FFFFFF;
    //  width: 100%;
    //  height: 100%;
    //}
    td,th{
      .op-btn{
        display: none;
      }
    }
    td:hover,th:hover{
      .op-btn{
        display: block;
        position: absolute;
        bottom:0;
        left: 0;
        background-color:rgba(0,0,0,0.7) ;
        color:#FFFFFF;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        >p{
          cursor: pointer;
        }
      }
    }

  }
  .add-btn{
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .add-btn-icon{
      display: none;
    }
  }
  .add-btn:hover{
    .add-btn-icon{
      display: block;
    }
  }
  tr:last-child{
    border-bottom: none;
  }
  .label-item{
    margin-right: 5px;
    padding: 0 5px;
    border: 1px solid #2d8cf0;
    height: 38px;
    line-height: 38px;
    border-radius: 4px;
    text-align: center;
    color:#2d8cf0;
  }
  .table-label{
    height: 40px;
    .add-label-icon{
      display: none;
    }
  }
  .table-label:hover{
    .add-label-icon{
      display: block;
    }
  }
}
</style>
