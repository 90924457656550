<template>
<div class="site-list">
  <div class="site-list-top">
<!--    <Input v-model="keyword"-->
<!--           :placeholder="$t('common_search_keyword')"-->
<!--           clearable-->
<!--           search-->
<!--           :enter-button="true"-->
<!--           @on-enter="changeKeyword"-->
<!--           @on-search="changeKeyword"-->
<!--           style="width: 300px"-->
<!--    />-->
    <Input v-model="keyword"
           placeholder="输入组织名称/ID搜索"
           clearable
           :enter-button="true"
           @on-enter="changeKeyword"
           @on-search="changeKeyword"
           style="width: 300px">
      <div slot="suffix" class="site-list-top-img">
        <img  src="../../../../assets/images/new_icon/sousuo.png" width="18" height="18" />
      </div>
    </Input>

  </div>
  <div class="site-list-cont">
    <Table border :columns="columns" :loading="loading" :data="siteList" ></Table>
    <div class="page">
      <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "siteList.vue",
  data(){
    return {
      columns:[
        {
          title: 'ID',
          key:'id',
          width:80,
          align:'center',
        },
        {
          title: '组织名称',
          key:'name',
          align:'center',
        },
        {
          title: '状态',
          key:'id',
          width: 120,
          align:'center',
          render:(h,params)=>{
            return h('span',params.row.map_to_site == '0' ? '未绑定' : '已绑定')
          }
        },
        {
          title: '操作',
          align:'center',
          width:120,
          render: (h, params) => {
            return h('div', [
              params.row.map_to_site == '0' ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.bindSite(1);
                  }
                }
              }, '绑定') : h('Button', {
                props: {
                  type: 'warning',
                  size: 'small',
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.bindSite(0);
                  }
                }
              }, '取消绑定'),
            ]);
          }
        },
      ],
      siteList:[],
      page:1,
      pageSize:10,
      keyword:'',
      total:0,
      loading:false,
      curData:{},
      mapId:'',
    }
  },
  created(){
    this.mapId = this.$route.params.id;
    this.getList();
  },
  methods:{
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword,
        map_id:this.mapId
      }
      this.loading = true;
      this.api.site.siteList(params).then((res)=>{
        this.siteList = res.list;
        this.total = Number(res.count);
        this.loading = false;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    bindSite(type){
      let params = {
        map_id:this.mapId,
        site_id:this.curData.id,
        check:type,
      }
      this.api.dataset.datasetMapToSiteUpdate(params).then((res)=>{
        this.$Message.success('操作成功');
        this.getList();
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    }
  }
}
</script>

<style scoped lang="scss">
.site-list{
  .site-list-top-img{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .site-list-cont{
    margin-top: 20px;
    width: 812px;

    .page{
      margin-top: 20px;
      text-align: right;
    }
  }
}
</style>
